<template>
  <div class="navbar">
    <nut-navbar @on-click-back="backClick" :title="title"></nut-navbar>
  </div>
  <Form class="form" ref="target" v-slot="{ errors }">
    <div class="goodsInfo">
      <div class="goodsInfo__rows">
        <div class="goodsInfo__rows__key">
          标题<span class="character">*</span>
        </div>
        <div>
          <Field
            class="goodsInfo__rows__value"
            name="goodsTitle"
            :rules="checkGoodsTitle"
            v-model="goods.title"
          />
        </div>
      </div>
      <div class="error" v-if="errors.goodsTitle">
        {{ errors.goodsTitle }}
      </div>
      <div class="goodsInfo__rows">
        <div class="goodsInfo__rows__key">
          名称<span class="character">*</span>
        </div>
        <div>
          <Field
            class="goodsInfo__rows__value"
            name="goodsName"
            :rules="checkGoodsName"
            v-model="goods.goodsName"
          />
        </div>
      </div>
      <div class="error" v-if="errors.goodsName">
        {{ errors.goodsName }}
      </div>
      <div class="goodsInfo__rows">
        <div class="goodsInfo__rows__key">
          进货价<span class="character">*</span>
        </div>
        <div>
          <Field
            name="costPrice"
            class="goodsInfo__rows__value"
            :rules="checkNotNull"
            v-model="goods.costPrice"
            :change="checkCostPrice(goods.costPrice)"
          />
        </div>
      </div>
      <div class="error" v-if="errors.costPrice">
        {{ errors.costPrice }}
      </div>
      <div class="goodsInfo__rows">
        <div class="goodsInfo__rows__key">
          折扣信息<span class="character">*</span>
        </div>
        <div>
          <Field
            name="discount"
            class="goodsInfo__rows__value"
            :rules="checkNotNull"
            v-model="goods.discount"
            :change="checkDiscount(goods.discount)"
          />
        </div>
      </div>
      <div class="error" v-if="errors.discount">
        {{ errors.discount }}
      </div>
      <div class="goodsInfo__rows" v-if="goodsId != 0">
        <div class="goodsInfo__rows__key">
          单价<span class="character">*</span>
        </div>
        <div>
          <input
            class="goodsInfo__rows__value"
            type="number"
            v-model="unitPrice"
            disabled
          />
        </div>
      </div>
      <div class="goodsInfo__rows" v-if="goodsId == 0">
        <div class="goodsInfo__rows__key">
          单价<span class="character">*</span>
        </div>
        <div>
          <input
            class="goodsInfo__rows__value"
            type="number"
            v-model="unitPrice"
            disabled
          />
        </div>
      </div>
      <div class="goodsInfo__rows" v-if="goodsId != 0">
        <div class="goodsInfo__rows__key">新增库存</div>
        <div>
          <input
            class="goodsInfo__rows__value"
            type="number"
            onkeyup="{value=value.replace(/^(0+)|[^\d]+/g,'')}"
            v-model="increment"
          />
        </div>
      </div>
      <div class="goodsInfo__rows" v-if="goodsId != 0">
        <div class="goodsInfo__rows__key">
          剩余库存<span class="character">*</span>
        </div>
        <div>
          <input class="goodsInfo__rows__value" v-model="residue" disabled />
        </div>
      </div>
      <div class="goodsInfo__rows" v-if="goodsId == 0">
        <div class="goodsInfo__rows__key">
          总库存<span class="character">*</span>
        </div>
        <div>
          <Field
            name="stock"
            :rules="checkNotNull"
            class="goodsInfo__rows__value"
            oninput="value=value.replace(/[^\d]|^0+/g,'')"
            v-model="goods.increment"
          />
        </div>
      </div>
      <div class="error" v-if="errors.stock">
        {{ errors.stock }}
      </div>

      <div class="goodsInfo__rows" v-if="goodsId == 0">
        <div class="goodsInfo__rows__key">
          剩余库存<span class="character">*</span>
        </div>
        <div>
          <input
            name="increment"
            class="goodsInfo__rows__value"
            v-model="goods.increment"
            disabled
          />
        </div>
      </div>
      <div class="error" v-if="errors.increment">
        {{ errors.increment }}
      </div>
      <div class="goodsInfo__rows" v-if="goodsId != 0">
        <div class="goodsInfo__rows__key">
          总库存<span class="character">*</span>
        </div>
        <div class="goodsInfo__rows__stock">
          {{ stock }}
        </div>
      </div>
      <div class="goodsInfo__rows">
        <div class="goodsInfo__rows__key">
          限购信息<span class="character">*</span>
        </div>
        <div>
          <nut-inputnumber v-model="goods.maxBuyNum" />
        </div>
      </div>
      <div class="goodsInfo__rows">
        <div class="goodsInfo__rows__key">
          类别<span class="character">*</span>
        </div>
        <div>
          <select v-model="goods.type">
            <option
              v-for="item in typeList.list"
              :value="item.value"
              :key="item.value"
            >
              {{ item.description }}
            </option>
          </select>
        </div>
      </div>
      <div class="goodsInfo__rows">
        <div class="goodsInfo__rows__key">
          状态<span class="character">*</span>
        </div>
        <div>
          <select v-model="goods.status">
            <option
              v-for="item in statusList.list"
              :value="item.value"
              :key="item.value"
            >
              {{ item.description }}
            </option>
          </select>
        </div>
      </div>
      <div class="goodsInfo__rows">
        <div class="goodsInfo__rows__key">描述</div>
        <div>
          <textarea
            maxlength="50"
            class="goodsInfo__rows__desc"
            v-model="goods.goodsDescription"
          />
        </div>
      </div>
      <div class="goodsInfoPic">
        <div class="goodsInfo__rows__key">图片</div>
        <div class="goodsInfoPic__pic">
          <nut-uploader
            :url="`/api/goods/uploadImg`"
            v-model:file-list="ImgList.ImgList"
            maximum="3"
            :maximize="1024 * 500"
            @success="handleSuccess"
            @oversize="onOversize"
            :headers="headers"
            :with-credentials="true"
          ></nut-uploader>
        </div>
      </div>
    </div>
  </Form>
  <div class="bottom">
    <nut-button type="info" color="#4fb0f9" block @click="handleSaveClick()"
      >保存</nut-button
    >
  </div>
  <nut-dialog
    :close-on-click-overlay="false"
    content="确认保存？"
    v-model:visible="visible"
    @ok="handleOk"
  ></nut-dialog>
</template>

<script setup>
import { reactive, ref, toRefs, onMounted, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import store from '@/store'
import { Field, Form } from 'vee-validate'
import { useFormValidate } from '@/utils/validate'
import {
  getGoodsInfo,
  reqChangeGoodsInfo,
  getGoodsStatus,
  reqGetGoodsType,
  reqAddGoodsInfo
} from '@/api'
import { Notify } from '@nutui/nutui'

const title = ref('')
const target = ref(null)
const route = useRoute()
const goodsId = parseInt(route.params.id)
const headers = reactive({
  'Access-Control-Allow-Origin': '*',
  Authorization: store.getters.token
})

if (goodsId === 0) {
  title.value = '新增商品信息'
} else {
  title.value = '编辑商品信息'
}
const ImgList = reactive({ ImgList: [] })
const ResGoodsImgList = reactive({ ResGoodsImgList: [] })
const data = reactive({
  goods: {
    stock: 0,
    residue: 0,
    status: 3
  }
})
const { goods } = toRefs(data)

// 表单校验方法
const { checkGoodsName, checkGoodsTitle, checkNotNull } = useFormValidate()

// 计算单价
const unitPrice = computed(() => {
  return (data.goods.costPrice * data.goods.discount).toFixed(2)
})
// 计算总库存（编辑）
const increment = ref(0)
const stock = computed(() => {
  return Number(increment.value) + Number(data.goods.stock)
})
// 计算剩余库存（编辑）
const residue = computed(() => {
  return Number(data.goods.residue) + Number(increment.value)
})
// 初始化页面
onMounted(async () => {
  if (goodsId !== 0) {
    await getGoods()
  }
  await getStatusList()
  await getTypeList()
})

const checkCostPrice = (value) => {
  if (value !== undefined) data.goods.costPrice = checkPrice(value)
}

const checkDiscount = (value) => {
  if (value !== undefined) data.goods.discount = checkPrice(value)
}

// 处理金钱显示
const checkPrice = (value) => {
  let invalidValue = '' + value
  invalidValue = invalidValue
    .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
    .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
    .replace('.', '$#$')
    .replace(/\./g, '')
    .replace('$#$', '.')
    .replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3') // 只能输入两个小数
  if (invalidValue.indexOf('.') < 0 && invalidValue !== '') {
    // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
    invalidValue = parseFloat(invalidValue)
  }
  return invalidValue
}

// 获取商品信息
const getGoods = async () => {
  const result = await getGoodsInfo({ goodsId: goodsId })
  data.goods = result
  result.goodsImgList.forEach((element) => {
    ImgList.ImgList.push({
      // ...element,
      isTop: element.isTop,
      sort: element.sort,
      url: element.src,
      type: 'image',
      status: 'success'
    })
  })
}
// 获取状态列表
const statusList = reactive({
  list: []
})
const getStatusList = async () => {
  const statusResult = await getGoodsStatus({
    key: 'goodsStatus'
  })
  statusList.list = statusResult.list
}
// 获取商品类别
const typeList = reactive({ list: [] })
const getTypeList = async () => {
  const typeResult = await reqGetGoodsType()
  typeList.list = typeResult.list
}
// 点击后退按钮
const router = useRouter()
const backClick = () => {
  router.push('/goodsManage')
}
// 上传图片成功回调
const handleSuccess = async (res) => {
  const resTxt = res.responseText
  const resJson = JSON.parse(resTxt)
  if (resJson.code !== 200) {
    res.fileItem.status = 'error'
    res.fileItem.message = '上传失败'
    return
  }
  res.fileItem.url = resJson.data.src
}
const onOversize = async (files) => {
  Notify.warn('图片大小不能超过 500 kb', {
    duration: 3000
  })
}

// 编辑商品信息
const changeGoodsInfo = async () => {
  for (var i = 0; i < ImgList.ImgList.length; i++) {
    ImgList.ImgList[i].sort = i + 1
    if (i === 0) {
      ImgList.ImgList[i].isTop = 1
    } else {
      ImgList.ImgList[i].isTop = 0
    }
  }
  ResGoodsImgList.ResGoodsImgList.splice(0)
  ImgList.ImgList.forEach((element) => {
    ResGoodsImgList.ResGoodsImgList.push({
      src: element.url,
      sort: element.sort,
      isTop: element.isTop
    })
  })
  data.goods.goodsImgList = ResGoodsImgList.ResGoodsImgList
  data.goods.stock = stock.value
  data.goods.unitPrice = unitPrice
  data.goods.residue = residue.value
  data.goods.increment =
    increment.value == null || increment.value === '' ? 0 : increment.value
  data.goods.launchTime = dateFormat(new Date())
  data.goods.maxBuyNum =
    data.goods.maxBuyNum == null || data.goods.maxBuyNum === 'NaN'
      ? 0
      : data.goods.maxBuyNum
  try {
    await reqChangeGoodsInfo(data.goods)
    Notify.success('保存成功', {
      duration: 1000
    })
  } catch (error) {
    Notify.danger(error, {
      duration: 3000
    })
  }
}
// 新增商品信息
const addGoodsInfo = async () => {
  for (var i = 0; i < ImgList.ImgList.length; i++) {
    ImgList.ImgList[i].sort = i + 1
    if (i === 0) {
      ImgList.ImgList[i].isTop = 1
    } else {
      ImgList.ImgList[i].isTop = 0
    }
  }
  ImgList.ImgList.forEach((element) => {
    ResGoodsImgList.ResGoodsImgList.push({
      src: element.url,
      sort: element.sort,
      isTop: element.isTop
    })
  })
  data.goods.goodsImgList = ResGoodsImgList.ResGoodsImgList
  data.goods.unitPrice = unitPrice
  data.goods.residue = data.goods.stock
  data.goods.launchTime = dateFormat(new Date())
  data.goods.maxBuyNum =
    data.goods.maxBuyNum == null || data.goods.maxBuyNum === 'NaN'
      ? 0
      : data.goods.maxBuyNum
  data.goods.stock = 0
  try {
    await reqAddGoodsInfo(data.goods)
    Notify.success('保存成功', {
      duration: 1000
    })
    router.push('/goodsManage/')
  } catch (error) {
    Notify.danger(error, {
      duration: 3000
    })
  }
}
// 对话框弹出
const visible = ref(false)
const showDialog = () => {
  visible.value = true
}
// 对话框ok回调
const handleOk = async () => {
  if (goodsId === 0) {
    await addGoodsInfo()
  } else {
    await changeGoodsInfo()
  }
}
// 点击保存按钮
const handleSaveClick = async () => {
  const valid = await target.value.validate()
  if (valid.valid) {
    showDialog()
  }
}
const dateFormat = (date) => {
  var year = date.getFullYear()
  /* 在日期格式中，月份是从0开始的，因此要加0
   * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
   * */
  var month =
    date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  var minutes =
    date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  var seconds =
    date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  // 拼接
  return (
    year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
  )
}
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
@import '@/style/customizedNutui.scss';
.navbar {
  position: fixed;
  top: 0px;
  width: 100%;
}
.goodsInfo {
  margin-left: 0.25rem;
  margin-top: 0.9rem;
  &__rows {
    display: flex;
    align-items: center;
    margin-top: 0.15rem;
    &__key {
      font-weight: bold;
      font-size: 0.16rem;
      width: 25%;
    }
    &__value {
      display: block;
      width: 2.2rem;
      border: none;
      outline: none;
      background: $search-bgColor;
      height: 0.35rem;
      font-size: 0.14rem;
      border-radius: 0.1rem;
      margin-left: 0.05rem;
      padding-left: 0.1rem;
    }
    &__desc {
      display: block;
      width: 210px;
      border: none;
      outline: none;
      background: $search-bgColor;
      height: 0.35rem;
      font-size: 0.14rem;
      border-radius: 0.1rem;
      margin-left: 0.05rem;
      height: 50px;
      padding: 10px;
    }
    &__stock {
      padding: 10px 20px 10px 0;
      margin-left: 0.1rem;
    }
  }
}
.goodsInfoPic {
  display: flex;
  margin-top: 0.15rem;
  flex: 1;
  &__pic {
    width: 2.4rem;
    margin-left: 0.1rem;
  }
}
select {
  width: 230px;
  height: 0.37rem;
  font-size: 0.14rem;
  background: $search-bgColor;
  border-radius: 0.1rem;
  margin-left: 0.05rem;
  border: none;
}
.bottom {
  position: absolute;
  margin-top: 0.3rem;
  width: 90%;
  background: #fcfcfc;
  padding: 10px 5%;
  flex-direction: row;
  display: flex;
  align-items: center;
}
.error {
  font-size: 0.12rem;
  margin-left: 0.95rem;
  color: $hightlight-fontColor;
}
.character {
  color: $hightlight-fontColor;
}
</style>
