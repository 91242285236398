<template>
  <div class="search">
    <div class="search__back iconfont" @click="handleBackClick">{{ icon }}</div>
    <div class="search__content">
      <span
        class="search__content__icon iconfont"
        @click="handleSearchClick(currentSearchText)"
      >
        &#xe741;
      </span>
      <input
        class="search__content__input"
        placeholder="请输入商品名称"
        v-model="currentSearchText"
        @keyup.enter="handleSearchClick(currentSearchText)"
      />
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { ref } from 'vue'

// 点击回退逻辑
const useBackRouterEffect = () => {
  const router = useRouter()
  const handleBackClick = () => {
    router.push({ name: 'Home' })
  }
  return { handleBackClick }
}

const useSearchGoods = (goodsName) => {
  const router = useRouter()
  const handleSearchClick = (goodsName) => {
    router.push({
      name: 'Shop',
      query: {
        goodsName: goodsName
      }
    })
  }
  return { handleSearchClick }
}

export default {
  name: 'Search',
  props: ['icon'],
  setup() {
    const currentSearchText = ref('')
    const { handleBackClick } = useBackRouterEffect()
    const { handleSearchClick } = useSearchGoods()
    return { handleBackClick, handleSearchClick, currentSearchText }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
.wrapper {
  padding: 0 0.18rem;
}
.search {
  display: flex;
  // margin: 0.14rem 0 0.04rem 0;
  line-height: 0.32rem;
  background: rgb(248, 248, 248);
  &__back {
    width: 0.3rem;
    font-size: 0.24rem;
    color: #b6b6b6;
  }
  &__content {
    display: flex;
    flex: 1;
    background: $search-bgColor;
    border-radius: 0.16rem;
    &__icon {
      width: 0.44rem;
      text-align: center;
      color: $search-fontColor;
    }
    &__input {
      display: block;
      width: 100%;
      padding-right: 0.2rem;
      border: none;
      outline: none;
      background: none;
      height: 0.32rem;
      font-size: 0.14rem;
      color: $content-fontcolor;
      &::placeholder {
        color: $content-notice-fontcolor;
      }
    }
  }
}
</style>
