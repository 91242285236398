<template>
  <div class="search">
    <Search icon="&#xe697;" />
  </div>
  <div class="wrapper">
    <div class="bgheaderPhoto"></div>
    <div class="homeshow">Welcome to snack bar</div>
    <div class="icons">
      <div
        v-for="(item, index) in this.iconList"
        :key="index"
        class="icons__item"
        @click="toGoodsType(item.value, item.typenum)"
      >
        <img class="icons__item__img" :src="item.src" />
        <p class="icons__item__desc">{{ item.desc }}</p>
      </div>
    </div>
    <div class="hotSale">
      <h2 class="hotSale__title">热销商品</h2>
      <div
        class="hotSale__item"
        v-for="item in hotSaleList"
        :key="item.id"
        @click="toHotSaleList(item.id)"
      >
        <img
          :src="item.goodsImg == null ? defaultPicURL : item.goodsImg"
          class="hotSale__item__img"
        />

        <div class="hotSale__content">
          <span class="hotSale__content__title">{{ item.goodsName }}</span>
          <span v-if="item.residue === 0" class="emptyResidue">售罄</span>
          <div class="hotSale__content__tags">
            <span class="hotSale__content__tag">已售：{{ item.used }} </span
            ><br />
            折扣价:<span class="hotSale__content__yen">&yen;</span>
            <span class="hotSale__content__tag hotSale__content__highlight"
              >{{ item.unitPrice.toFixed(2) }}
            </span>
            原价:<span class="hotSale__content__tag" id="orginal">
              &yen;{{ item.costPrice.toFixed(2) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Docker :currentIndex="0" dockerName="Home" />
</template>

<script>
import { ref } from 'vue'
import Docker from '@/components/Docker'
import Search from '@/components/Search'
import router from '@/router/index'
import { reqGetGoodsList } from '@/api'

// 列表内容相关的逻辑

export default {
  name: 'Home',
  components: { Docker, Search },
  setup() {
    const defaultPicURL = '/images/defaultPic.png'
    const hotSaleList = ref([])
    async function getValue() {
      let count = 0
      const result = await reqGetGoodsList({
        goodsType: '1',
        goodsName: '',
        goodsStatus: 1,
        page: 1,
        orderBy: 'used',
        sort: 'desc'
      })

      for (const iterator of result.list) {
        if (count !== 5) {
          hotSaleList.value.push(iterator)
          count++
        }
      }
    }
    getValue()
    const iconList = [
      { src: 'images/all.png', desc: '全部商品', typenum: '1' },
      { src: 'images/drink.png', desc: '果汁饮品', typenum: '2' },
      { src: 'images/food.png', desc: '零食快餐', typenum: '3' },
      { src: 'images/daily.png', desc: '生活日用', typenum: '4' }
    ]
    function toHotSaleList(id) {
      router.push(`/shop/goodsDetail/${id}`)
    }
    function toGoodsType(tab, type) {
      router.push({
        name: 'Shop',
        query: {
          type: type
        }
      })
    }
    return { hotSaleList, toHotSaleList, defaultPicURL, iconList, toGoodsType }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
@import '@/style/mixins.scss';
.search {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  padding: 0.14rem 0.18rem 0.04rem 0.18rem;
}
.wrapper {
  overflow-y: auto;
  position: absolute;
  left: 0;
  top: 0.5rem;
  bottom: 0.5rem;
  right: 0;
  background: rgb(248, 248, 248);
  padding: 0 0.18rem;
}
.homeshow {
  margin: 0.16rem 0 0.02rem 0;
  height: 100px;
  width: 100%;
  padding-bottom: 20%;
  line-height: 200px;
  text-align: center;
  font-size: 20px;
  font-family: cursive;
  text-shadow: 6px 2px 2px #666666;
  background: url('../../../public/images/supermarket.jpg') no-repeat;
  background-size: 100%, 100%;
  animation: myfirst 5s;
  animation-iteration-count: 400;
}
@keyframes myfirst {
  0% {
    color: pink;
  }
  25% {
    color: yellow;
  }
  50% {
    color: blue;
  }
  75% {
    color: green;
  }
}
.icons {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.16rem;
  justify-content: space-around;
  &__item {
    width: 25%;
    &__img {
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      margin: 0 auto;
      background: #f1f1f1;
    }
    &__desc {
      margin: 0.06rem 0 0.05rem 0;
      text-align: center;
      color: $content-fontcolor;
    }
  }
}
.hotSale {
  #orginal {
    text-decoration: line-through;
    font-size: 0.1rem;
  }
  &__title {
    margin: 0.16rem 0rem 0.1rem 0rem;
    font-size: 0.18rem;
    color: $content-fontcolor;
  }
  &__item {
    padding: 0.12rem 0;
    display: flex;
    position: relative;
    margin: 0rem;
    border-bottom: 0.01rem solid $content-bgColor;
    &__img {
      width: 0.6rem;
      height: 0.6rem;
      margin-right: 0.16rem;
    }
  }
  &__content {
    flex: 1;
    &__title {
      margin: 0;
      line-height: 0.2rem;
      font-size: 0.14rem;
      color: $content-fontcolor;
      @include ellipsis;
      font-weight: bold;
    }
    &__tags {
      margin: 0.06rem 0;
      line-height: 1.15;
      font-size: 0.12rem;
    }
    &__tag {
      margin: 0;
    }
    &__yen {
      font-size: 0.1rem;
      color: #e93b3b;
    }
    &__highlight {
      margin: 0.08rem 0 0 0;
      line-height: 0.18rem;
      font-size: 0.13rem;
      font-weight: bold;
      color: #e93b3b;
    }
  }
  .emptyResidue {
    color: red;
    margin-left: 0.1rem;
    font-size: 0.14rem;
  }
}
</style>
