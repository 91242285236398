<template>
  <div class="docker">
    <div
      v-for="(item, index) in dockerList"
      :class="{
        docker__item: true,
        'docker__item--active': index === currentIndex
      }"
      :key="item.icon"
    >
      <router-link :to="item.to">
        <div class="iconfont" v-html="item.icon" />
        <div class="docker__title">{{ item.text }}</div>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import store from '@/store'

const homeIcon = store.getters.ENV === 'DEV' ? '&#xe6dc;' : '&#xe603;'
// dockerList
const dockerList = [
  { _id: 1, icon: homeIcon, text: '首页', to: { name: 'Home' } },
  { _id: 2, icon: '&#xe638;', text: '购物', to: { name: 'Shop' } },
  { _id: 3, icon: '&#xe61f;', text: '订单', to: { name: 'OrderList' } },
  { _id: 4, icon: '&#xe660;', text: '我的', to: { name: 'My' } }
]

defineProps({
  currentIndex: Number
})
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
.docker {
  display: flex;
  box-sizing: border-box;
  position: absolute;
  padding: 0 0.18rem;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.49rem;
  border-top: 0.01rem solid $content-bgColor;
  background-color: $bgColor;
  &__item {
    flex: 1;
    text-align: center;
    a {
      color: $medium-fontColor;
      text-decoration: none;
    }
    .iconfont {
      margin: 0.07rem 0 0.02rem 0;
      font-size: 0.18rem;
    }
    &--active {
      a {
        color: #1fa4fc;
      }
    }
  }
  &__title {
    font-size: 0.2rem;
    transform: scale(0.5, 0.5);
    transform-origin: center top;
  }
}
</style>
